import { useState } from "react";
import "./Header.css";

function Header() {
  return (
    <div className="logo justify-center">
        <img src="/logo.svg" alt="logo" width="200px"/>
    </div>
  );
}

export default Header;
