import { useEffect, useState } from "react";
import "./App.css";
import VykingSneakerWindow from "./components/VykingSneakerWindow";
import models from './assets/models.json';

export async function loader({ params }) {
  const model = models.shoes.find(model => model.id === params.id);

  if (model === undefined) {
    throw new Error("Model not found.");
  }

  return `https://djml4n8go355z.cloudfront.net/${model.model}/offsets.json`;
}

function App() {
  const [vykingConfig, setVykingConfig] = useState(null);

  const targetOrigin = "https://d1ux9mupljc68q.cloudfront.net";
  const targetPath = "/1/index.html";

  useEffect(() => {
    async function loadVykingConfig() {
      try {
        const config = await window.loadVykingConfig;
        setVykingConfig(config);
      } catch (err) {
        alert(err);
      }
    }

    loadVykingConfig();
  }, []);

  return (
    <div className="App">
      {vykingConfig && (
        <VykingSneakerWindow
          config={vykingConfig}
          targetOrigin={targetOrigin}
          targetPath={targetPath}
        />
      )}
    </div>
  );
}

export default App;
