import { useCallback, useEffect, useRef, useState } from "react";
import "./VykingSneakerWindow.css";
import { useLoaderData } from 'react-router-dom';

function VykingSneakerWindow({ config, targetOrigin, targetPath }) {
  const initialAccessoryUrl = useLoaderData();
  const [isReady, setIsReady] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const vykingSneakerWindowRef = useRef(null);

  const postInitialConfig = useCallback(
    (clientWidth, clientHeight) => {
      let cameraWidth = 360;
      let cameraHeight = 640;
      if (clientWidth > clientHeight) {
        cameraWidth = 640;
        cameraHeight = 360;
      }

      if (vykingSneakerWindowRef.current !== null) {
        vykingSneakerWindowRef.current.contentWindow.postMessage(
          {
            type: "VYKING_SNEAKER_WINDOW_CONFIG",
            config,
            key: window.configKey,
            cameraWidth: cameraWidth,
            cameraHeight: cameraHeight,
            autoPlay: true,
            accessoryDescriptionUrl: initialAccessoryUrl
      }, targetOrigin);
    }
    },
    [config, vykingSneakerWindowRef,initialAccessoryUrl, targetOrigin]
  );

  const messageEventHandler = useCallback(
    (event) => {
      const eventData = event.data;
      const eventType = eventData && event.data.type;

      switch (eventType) {
        case "VYKING_SNEAKER_WINDOW_WAITING_FOR_CONFIG":
          postInitialConfig(640, 360);
          break;

        case "VYKING_SNEAKER_WINDOW_READY":
          setIsLoading(false);
          setIsReady(true);

          break;

        case "VYKING_SNEAKER_WINDOW_REPLACE_ACCESSORIES":
          if (eventData.complete === 1) {
            setIsLoading(false);
          }
          break;

        case "VYKING_SNEAKER_WINDOW_ARE_FEET_DETECTED":
          console.log(`Are feet detected: ${eventData.value}`);
          break;

        case "VYKING_SNEAKER_WINDOW_TAKE_PHOTO":
          //
          break;

        case "VYKING_SNEAKER_WINDOW_EXPIRY_TIME":
          console.info(
            `Licence expiry date: ${eventData.expiryTime.toString()}`
          );
          break;

        case "VYKING_SNEAKER_WINDOW_BUSY_ERROR":
          setIsLoading(false);
          break;

        // An error has occurred
        case "VYKING_SNEAKER_WINDOW_ERROR":
          setIsLoading(false);
          alert(`${eventData.requestType} ${eventData.value}`);
          break;

        default:
          break;
      }
    },
    [postInitialConfig]
  );

  useEffect(() => {
    window.addEventListener("message", messageEventHandler);
    return () => {
      window.removeEventListener("message", messageEventHandler);
    };
  }, [messageEventHandler]);

  return (
    <>
      <section className="feature">
        <div className="vyking-sneaker-window-wrapper">
          {isLoading && (
            <div className="loader-image h-full">
              <img src={require("../assets/loader.gif")} alt="Loading..." />
            </div>
          )}
          <div
            className="vyking-sneaker-window-contents"
            style={{ visibility: isReady && !isLoading ? "visible" : "hidden" }}
          >
            <iframe
              className="vyking-sneaker-window"
              name="vykingSneakerWindow"
              src={encodeURI(targetOrigin + targetPath)}
              ref={vykingSneakerWindowRef}
              title="Vyking Sneakerkit Window"
              allow="camera"
            >
              Try-on with webcam
            </iframe>
          </div>
        </div>
      </section>
      
    </>
  );
}

export default VykingSneakerWindow;
