import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App, { loader as appLoader } from './App';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter, Outlet, redirect,
  RouterProvider, useRouteError,
} from "react-router-dom";
import Header from './components/Header';

async function defaultLoader() {
  return redirect('/giro');
}

function Default() {

  return (
    <span
      style={{
        position: 'absolute',
        inset: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
      }}
    >
      This is the virtual try on experience.
    </span>
  )
}

function Root() {

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    }}>
      <Header/>
      <Outlet />
    </div>
  )
}

function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <div id="error-page">
      <Header />

      <div
        style={{
          position: 'absolute',
          inset: 0,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
        }}
      >
        <p>Sorry, an unexpected error has occurred.</p>
        <p>
          <i>{error.statusText || error.message}</i>
        </p>
      </div>
    </div>
  );
}

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/',
        element: <Default />,
        loader: defaultLoader,
      },
      {
        path: ':id',
        element: <App />,
        loader: appLoader,
      },
    ]
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
